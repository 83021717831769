.review-reimbursement__popup .Modal.visible {
  align-items: center;
}

.review_reimbursements {
  color: #282828;
  .ParkingTolls-Block {
    margin: 0
  }
  .Box-Content{
    margin: 0;
    padding-bottom: 0;
  }
  .ParkingTolls-Checkbox {
    margin-top: 5px;
    i {
      color: white
    }
    input[type=checkbox]:checked+label:before {
      background-color: var(--color-red);
      border-color: var(--color-red);
    }
  }
  .ParkingTolls-Checkbox label {
    font-size: 16px;
    &:before {
      border-color: #a1a1a1;
    }
    &.red {
      color: var(--color-red) !important
    }
  }
  table {
    border-collapse: collapse;
  }
  .review_reimbursements__fees {
    display: flex;
    align-items: center;
    font-size: 14px;
    div:first-child {
      font-style: italic;
      flex: 1;
    }
    .review_reimbursements__invoice {
      display: flex;
      font-size: 16px;
      align-items: center;
      span{
        font-size: 20px;
        margin-left: 10px;
        font-weight: 600;
        color: #444444
      }
    }
  }
  .review_reimbursements__footer {
    padding: 15px 20px 10px 20px;
    box-shadow: 0 -5px 5px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
    text-align: left;
  }
  .review_reimbursements__table{
    text-align: left;
  }
  .review_reimbursements__action {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    button {
      width: 315px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: unset;
      font-size: 17px;
      font-weight: bold;
    }
    .confirm {
      background-color: #3fae29;
      color: #ffffff;
      margin-left: 10px;
      &:hover{
        background-color:  var(--color-dark-green);
      }
    }
    .back {
      background-color: #c7c7c7;
      color: #444444;
      &:hover{
        background-color:  var(--color-gray);
      }
    }
  }

  .grid-table {
    width: 700px;
  }

  .grid-cell {
    font-size: 16px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    box-sizing: border-box;
    background: #f9f9f9;
    &--header {
      text-align: center;
      padding: 2px 15px 5px 15px;
      flex-direction: column;
    }
    &--free {
      padding-bottom: 20px;
    }
  }
  .grid-cell__free {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .confirm-reimbursement__table--bottom-right::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .row-location {
    background-color: var(--color-white) !important;
    display: flex;
    justify-content: start;
    text-align: left;
  }
}
.review_reimbursements__header {
  background-color: #3fae29;
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.review-reimbursement__popup {
  .cell-header__name {
    display: contents!important;
  }
}

.review-reimbursement__id {
  color: #ffdb00;
}
.review_reimbursements__title {
  font-size: 17px;
  font-weight: bold;
  padding: 18px;
  text-align: center;
  color: white
}
.review_reimbursements__timer {
  text-align: center;
  padding: 5px;
  background-color: #ffdb00;
  font-size: 12px;
  color: #282828;
}
.review_reimbursements__table {
  box-sizing: border-box;
  width: 644px;
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
  margin-left: 280px;
}
.review_reimbursements__table table {
  border: #dbdbdb;
}
.ParkingTolls-Price {
  margin: 0 20px;
  padding: 15px 0px !important;
}

.review_reimbursements__note {
  display: flex;
  justify-content: space-between;
  margin: 15px -20px 0 -20px;
  padding: 10px 20px;
  background-color: #ffdb00;
  color: #282828;
  font-weight: 500;
}

.header__free{
  border: 1px solid #eee;
  font-size: 13px;
  text-align: center;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
	margin-top: 2px;
}
.free-parking-tolls {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: -200px;
  right: 0;
  text-align: center;
  bottom: 5px;
  padding: 5px;
}

.free-parking-tolls-cb-one {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  text-align: center;
  bottom: 5px;
  padding: 5px;
}
.header__free_merge {
  display: inline-block;
  background-color: var(--color-white);
}
.cell-header__name {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
// responsive for mobile
.locating-container .review_reimbursements.mobile + .Map {
  display: none;
}
.Modal.visible.review_reimbursements.mobile {
  flex: 1;
  display: block;
  .Popup-Booking-Normal {
    padding-bottom: 60px;
    &.note {
      padding-bottom: 148px;
    }
  }
  .Popup .Box-Content{
    margin: unset;
    border-radius: unset;
    .review_reimbursements__header {
      border-radius: unset;
      .review_reimbursements__title {
        font-size: 14px;
        color: #a1a1a1;
        font-weight: 400;
        padding: 8px;
        background-color: white;
      }
      .review-reimbursement__id {
        display: block;
        color: #282828;
        font-size: 18px;
        font-weight: 400;
      }
      .review_reimbursements__timer{
        background-color:#feda31;
        span {
          line-height: unset;
        }
      }
    }
  }
  .reimbursement-list {
    >div {
      border-bottom: 1px solid #dbdbdb;
    }
  }
  .ParkingTolls-Group-Pin {
    padding: 15px;
    background-color: #f1f1f1;
    label {
      font-size: 14px;
      color: #282828;
    }
  }
  .reimbursement-list-child {
    padding: 15px;
    >div:not(:last-child) {
      padding-bottom: 15px;
      border-bottom: 1px solid #dbdbdb;
    }
    >div:not(:first-child) {
      padding-top: 15px;
    }
    border-bottom: 1px solid #dbdbdb;
  }
  .reimburse-col {
    display: flex;
    align-items: center;
    color: #282828;
    font-size: 14px;
    >div:first-child {
      flex: 1;
    }
    .col-fees {
      font-weight: 500;
      margin-top: 5px;
    }
    &.red {
      color: var(--color-red);
      .col-fees {
        text-decoration: line-through;
      }
    }
    .col-predefined {
      margin-top: 5px;
    }
    .Reibursement-image {
      position: relative;
      img {
        width: 55px;
        height: 55px;
      }
      &:not(:last-child) {
        padding-right: 10px;
        border-right: 1px solid #dbdbdb;
      }
    }
    .reimbursement-image-viewall.abs{
      bottom: 3px;
      height: unset;
    }
    .grid-cell__free {
      padding-left: 15px;
      label {
        color: #282828;
      }
    }
  }
  .reimbursement-summary-wrap {
    padding: 15px;
    background-color: #f1f1f1;
    font-size: 13px;
    color: #282828;
    .reimbursement-summary {
      border: 1px solid #dbdbdb;
      background-color: white;
    }
    .summary-title {
      padding: 5px 12px;
    }
    .summary-body {
      padding: 0px 12px 12px 20px;
    }
    .summary-row {
      display: flex;
      .summary-row-line {
        position: relative;
        min-width: 15px;
        margin: 0 5px;
        flex: 1;
        &::before {
          content: "";
          width: 100%;
          position: absolute;
          border-bottom: 1px dashed rgb(219, 219, 219);
          bottom: 5px;
        }
      }
      .summary-row-name {
        background: rgb(255, 255, 255);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .summary-row-fees {
        background: rgb(255, 255, 255);
      }
    }
    .summary-footer {
      display: flex;
      font-size: 14px;
      color: #40ae29;
      padding: 8px 12px;
      border-top: 1px solid #40ae29;
      >div:first-child {
        flex: 1;
        font-size: 13px;
        font-weight: bold;
      }
    }
  }
  .review_reimbursements__footer {
    padding: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
  }
  .review_reimbursements__action {
    button {
      width: 100%;
    }
  }
}

.disputed {
  margin: auto 0;
}

.PopupConfirm {
  color: #3fae29 !important;
  background-color: #ffffff !important;
  font-size: 17px !important;
  padding: 0 5px;
  line-height: inherit;
  min-height: 34px;
  border: 1px solid var(--color-white);
  text-align: center;
  display: block;
  text-transform: capitalize;
  width: 100%;
  outline: 0;
  cursor: pointer;
  font-weight: 700;
  border-radius: 4px;
  &:hover{
    background-color:  var(--color-dark-green) !important;
    color: var(--color-white) !important;
  }
}

.ContentPopup {
  font-size: 16px;
  text-align: center;
}

.Box-Icon-Confirm {
  margin-top: -23px !important;
  background: none !important;
  img {
    width: 83px !important;
    height: 83px !important;
  }
}

.mt-15 {
  margin-top: 15px !important;
}

.reimbursement-image-block {
  width: 55px;
  height: 55px;
}

.reimbursement-image-viewall {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.50);
  color: white;
  font-size: 12px;
  padding: 0px 5px;
  text-align: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  &.abs{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}